// Import React or React components
import React from "react"

// Get needed core function
import { fetchWPAPI } from '../core'

// Import needed interfaces
import { ITürchen } from "../interfaces"

// Declare main component
const Türchen: React.FC<ITürchen> = ({number, date, openDoors, setOpenDoors, setDoorJson, setError, setShowLoadingCircle}) => {

    // Declare needed State
    const [extraClasses, setExtraClasses] = React.useState('');

    // Check if Door can be opened today
    const getActive = () => {
        return true; // ( date.getMonth() === 11 && date.getDate() >= parseInt ( number ) );
    };

    // Get Json of clicked Door
    const getWPJson = async () => {
        if ( ! getActive() ) {
            setError({ hasError: true, errorMessage: 'Dieses Türchen kann noch nicht geöffnet werden.' });
            return;
        }
        setShowLoadingCircle({ show: true, white: true });

        const json = await fetchWPAPI(`/wordpress/wp-json/react/v1/doors?number=${number}`);

        if(json.error) {
            setShowLoadingCircle({ show: false, white: false });
            setError({ hasError: true, errorMessage: json.error });
            return;
        }

        if ( json[0].active !== 'on' ) {
            setShowLoadingCircle({ show: false, white: false });
            setError({ hasError: true, errorMessage: 'Dieses Türchen kann noch nicht geöffnet werden.' });
            return;
        }

        if ( ! openDoors[number] )
            ( openDoors.hasOwnProperty ( 'placeholder' ) ) ? setOpenDoors ( { [number]: true} ) : setOpenDoors ( {...openDoors, [number]: true } );

        if(extraClasses !== 'clicked')
            setExtraClasses ( 'clicked startAnimation' )
            setTimeout ( () => {
                setExtraClasses ( 'clicked' );
            }, 1000 );

        setDoorJson(json);
        setShowLoadingCircle({ show: false, white: false });
    };

    React.useEffect(() => {
        if ( extraClasses !== 'clicked startAnimation' )
            setExtraClasses((openDoors[number]) ? 'clicked' : '');
    }, [openDoors, number]);

    // Return fully rendered HTML
    return (
        <button className={`door ${(getActive()) ? 'active' : 'notActive'} num-${number} ${extraClasses}`} onClick={getWPJson} title={`Öffne das ${number}. Türchen!`}>
            <span className="doorWrap">
                <span className="innerDoor">
                    <span className="innerContainer">
                        <span className="number">{number}</span>
                    </span>
                </span>
            </span>
            <span className="outerDoor"></span>
        </button>
    )
};

// Export component to be used anywhere else
export default Türchen;