// Import React or React components
import React from "react"

// Import needed interfaces
import { IHeadline } from "../interfaces"

const Headline: React.FC<IHeadline> = ({data}) => {

    const Tag = ( data.options_span ) ? 'span' : 'h2';

     // Return fully rendered HTML
    return (
        <Tag className={`headline ${data.options_classes}`}>
            {
                ( data.options_line === 'headline_upper' )
                    ? <span>{data.headline_upper}</span>
                    : data.headline_upper
            }
            {
                ( data.options_line === 'headline_lower' )
                    ? <span>{data.headline_lower}</span>
                    : data.headline_lower
            }
        </Tag>
    );
};

// Export component to be used anywhere else
export default Headline;