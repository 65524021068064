// Import React or React components
import React from "react"

// Import needed interfaces
import { ITrennstrich } from "../interfaces"

// Declare main component
const Trennstrich: React.FC<ITrennstrich> = ({ data }) => {

    const getClasses = () => {
       return ( data.gap_direction === 'both' ) ? data.gap + 'Top ' + data.gap + 'Bottom' : data.gap + data.gap_direction;
    };

    // Return fully rendered HTML
    return (
        <span className={`trennstrich ${getClasses()}`} />
    )
};

// Export component to be used anywhere else
export default Trennstrich;