// Import React or React components
import React from "react"

// Import needed interfaces
import { IErrorHandler } from "../interfaces"

// Declare main component
const ErrorHandler: React.FC<IErrorHandler> = ({ error, setError }) => {

    const [extraClasses, setExtraClasses] = React.useState('');

    React.useEffect(() => {
        setTimeout(() => {

            setExtraClasses('closing');

            setTimeout(() => {
                setError({ errorMessage: '', hasError: false });
            }, 500)
        }, 5000);
    });

     // Return fully rendered HTML
    return (
        <div className={`errorHandler ${extraClasses}`}>
            <span className="message">{error.errorMessage}</span>
        </div>
    );
};

// Export component to be used anywhere else
export default ErrorHandler;