export const fetchWPAPI = async (url: string) => {
    // make api calls to port 8080 if hosted locally
    const port = (window.location.hostname === 'localhost') ? ':8888' : '';
    const mainURL = window.location.protocol + '//' + window.location.hostname + port;


    try {
        const response = await fetch(`${mainURL}${url}`);
        return await response.json();
    } catch (error) {
        return { error: 'Failed to fetch destination' }
    }

};

export const setCookie = (cookieName: string, cookieValue: string, expires?: Date) => {
    const cookieString = cookieName + '=' + cookieValue + ';' + ((expires) ? 'expires=' + expires.toUTCString() + ';' : '') + 'path=/';
    document.cookie = cookieString;
};

export const getCookie = (cookieName: string): string => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    cookieName += '=';

    for(let i = 0; i < cookieArray.length; i++) {
        let value = cookieArray[i];
        while (value.charAt(0) === ' ') {
            value = value.substring(1);
        }

        if (value.indexOf(cookieName) === 0)
            return value.substring(cookieName.length, value.length);
    }

    return '';
};