// Import React or React components
import React from 'react'

// Import needed interfaces
import { IDoorJson, IError, IOpenDoors } from "../interfaces"

// Import needed core Functions
import { getCookie, setCookie } from '../core'

// Import needed components
import ErrorHandler from './ErrorHandler'
import LoadingCircle from './LoadingCircle'
import Türchen from './Türchen'
import Overlay from "./Overlay"

const Home: React.FC = () => {

    const [doorJson, setDoorJson] = React.useState<IDoorJson>();
    const [error, setError] = React.useState<IError>({ hasError: false, errorMessage: "" });
	const [showLoadingCircle, setShowLoadingCircle] = React.useState({show: false, white: true});
    const [openDoors, setOpenDoors] = React.useState<IOpenDoors>({ 'placeholder': true });
    const date = new Date();
    const doors = ['04', '13', '09', '20', '07', '03', '10', '16', '22', '15', '02', '06', '17', '23', '12', '01', '08', '18', '11', '19', '05', '21', '14', '24'];

    // Effect that triggers on component mount
    React.useEffect ( () => {

        // Try to get cookie
        const doorCookie = getCookie ( 'openDoors' );

        // Define default cookie value
        const openDoorsDefault = { '01': false, '02': false, '03': false, '04': false, '05': false, '06': false, '07': false, '08': false, '09': false, '10': false, '11': false, '12': false, '13': false, '14': false, '15': false, '16': false, '17': false, '18': false, '19': false, '20': false, '21': false, '22': false, '23': false, '24': false }

        // Check for door cookie
        if ( doorCookie === '' ) {

            // Set cookie everytime open door state changes
            setCookie ( 'openDoors', JSON.stringify ( openDoorsDefault ), new Date ( 'February 31, 2023 23:59:00' ) );

        // Cookie found
        } else {

            // Set open doors based on cookie value
            const doorCookieObj: IOpenDoors = JSON.parse ( doorCookie );
            setOpenDoors ( doorCookieObj );

        }

    }, [] );

    // Use effect to trigger when openDoors state changes
    React.useEffect ( () => {

        // Check for open doors placeholder
        if ( ! openDoors.hasOwnProperty ( 'placeholder' ) ) {

            // Set current value is new cookie if not default state
            setCookie ( 'openDoors', JSON.stringify ( openDoors ), new Date ( 'December 31, 2022 23:59:00' ) );
        }

    }, [ openDoors ] );

    // Return fully rendered HTML
    return (
        <>
            <div className="adventsCalender">
                <div className="topBar">
                    <span className="branding"></span>
                    <span className="headline"><b>Adventskalender</b> 2022</span>
                </div>
                <div className="doors">
                    {
                        doors.map((value) =>
                            <Türchen key={value} number={value} date={date} openDoors={openDoors} setOpenDoors={setOpenDoors} setError={setError} setDoorJson={setDoorJson} setShowLoadingCircle={setShowLoadingCircle} />
                        )
                    }
                    <div className="houseHolder"><span className="peopleHolder"></span></div>
                </div>
            </div>
            <Overlay isLoaded={doorJson !== undefined} doorJson={doorJson} setDoorJson={setDoorJson} key={'Overlay'} />
            {
                // render Error if necessary
                (error.hasError)
                    ? <ErrorHandler error={error} setError={setError} />
                    : <></>
            }
        </>
    );
};

// Export component to be used anywhere else
export default Home;