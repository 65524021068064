// Import React or React components
import React from "react"

// Import needed interfaces
import { IImage } from "../interfaces"

// Declare main component
const Image: React.FC<IImage> = ( { data } ) => {

    // Define needed variables
    const WrapperTag = ( data.external ) ? 'a' : 'div';

    // Return fully rendered HTML
    return (
        <WrapperTag className="image" {... ( data.external ) ? { href: data.external, title: '' } : {}}>
            <picture>
                <source srcSet={ data.upload.url.replace ( '/uploads/', '/webp-express/webp-images/uploads/' ) + '.webp' } type="image/webp" />
                <img loading="lazy" className="" alt={ ( data.upload.alt !== '' ) ? data.upload.alt : data.upload.title } src={ data.upload.url } style={ { objectPosition: data.direction } } />
            </picture>
            {
                ( data.copyright !== '' )
                    ? <span className="copyright">{ data.copyright }</span>
                    : <></>
            }
        </WrapperTag>
    );
};

// Export component for external usage
export default Image;