// Import React or React components
import React from "react"

// Import needed interfaces
import { IOverlay } from "../interfaces"

// Import needed Components
import EditorContent from "./Editor"
import Headline from "./Headline"
import Audio from "./Audio"
import Image from "./Image"
import Trennstrich from "./Trennstrich"

// Declare main component
const Overlay: React.FC<IOverlay> = ({ isLoaded, doorJson, setDoorJson}) => {

    // Declare needed States
    const [extraClasses, setExtraClasses] = React.useState('');
    const [visibility, setVisibility] = React.useState('');

    // Use timer to remove initial class
    React.useEffect ( () => {
        if ( isLoaded ) {
            document.body.classList.add ( 'overlayOpen' );
            setExtraClasses ( 'inScope' );
            setTimeout ( () => {
                setExtraClasses ( 'inScope open' );
            }, 100 );
        } else {
            document.body.classList.remove ( 'overlayOpen' );
        }
    }, [isLoaded] );

    // Return empty HTML element if doorJson isn't loaded
    if ( doorJson === undefined ) return <></>;

    // Define method to close overlay
    const closeOverlay = () => {
        setExtraClasses ( 'inScope closing' );

        setTimeout ( () => {
            setDoorJson ( undefined );
            setExtraClasses ( '' );
        }, 750 );
    };

    // Return fully rendered HTML
    return (
        <>
            <div className={`overlay ${ extraClasses }`} style={{ display: 'none' }}>
                <button className="closeOverlay" onClick={ closeOverlay } title="Schließe das aktuelle Türchen!"></button>
                <div className="row">
                    <button className="closeOverlay single" onClick={ closeOverlay } title="Schließe das aktuelle Türchen!"></button>
                    <div className="col">
                    {

                        // Loop through ACF array
                        doorJson[0].acf.content.map ( ( currentObj, index ) => {
                            if ( currentObj.acf_fc_layout === 'headline' && currentObj.headline !== undefined )
                                return <Headline data={ currentObj.headline } key={ `overlayChild${ index }` } />

                            if ( currentObj.acf_fc_layout === 'image' && currentObj.image !== undefined )
                                return <Image data={ currentObj.image } key={ `overlayChild${ index }`} />

                            if ( currentObj.acf_fc_layout === 'editor' && currentObj.editor !== undefined )
                                return <EditorContent data={ currentObj.editor } key={ `overlayChild${index}` } />

                            if ( currentObj.acf_fc_layout === 'audio' && currentObj.audio !== undefined )
                                return <Audio data={ currentObj.audio } key={ `overlayChild${ index }` } />

                            if ( currentObj.acf_fc_layout === 'trennstrich' && currentObj.trennstrich !== undefined )
                                return <Trennstrich data={ currentObj.trennstrich } key={ `overlayChild${ index }` } />

                            return <></>
                        })
                    }
                    </div>
                </div>
            </div>
        </>
    )
};

// Export component to be used anywhere else
export default Overlay;