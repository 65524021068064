// Import React or React components
import React from "react"

// Import needed interfaces
import { IEditorContent } from "../interfaces"


const EditorContent: React.FC<IEditorContent> = ({data}) => {

     // Return fully rendered HTML
    return (
        <div className="editorContent" dangerouslySetInnerHTML={{ __html: data.editor_content }} />
    )
};

// Export component to be used anywhere else
export default EditorContent;