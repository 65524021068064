// Import needed React-Router-DOM components
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Import needed components
import Home from "./components/Home"

// Declare main component
const App: React.FC = () => {
	return (
		<div className="AppWrapper">
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route path="" element={ <Home /> } />
					</Routes>
				</BrowserRouter>
			</div>
			<div className="snowFlakeHolder">
			{ [ ...Array ( 50 ) ].map ( ( x, i ) =>
				<span className="snowflake" key={ i } />
			)}
			</div>
		</div>
	);
};

// Export component to be used anywhere else
export default App;
