// Import React or React components
import React from 'react'

// Import ReactDOM or ReactDOM components
import ReactDOM from 'react-dom/client'

// import needed Components
import App from './App'

// import styling
import './assets/css/styles.css'

// Create App Root
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Render Root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);