// Import React or React components
import React from "react"

// Import needed interfaces
import { IAudio } from "../interfaces"

// Declare main component
const Audio: React.FC<IAudio> = ({ data }) => {

    // Define needed states
    const [extraClasses, setExtraClasses] = React.useState('');
    const [showOverlay, setShowOverlay] = React.useState(true);
    const [showAudioOptions, setShowAudioOptions] = React.useState({ show: false, isAllowed: false });

    // Define needed variables
    const song = require(`../assets/songs/${data.filename}`);
    const volume = data.volume as unknown as number;
    const delay = data.delay as unknown as number;


    const playAudio = React.useCallback(() => {
        // get audio tag
        const backgroundSong = document.getElementById('backgroundSong');
        const audioTag: HTMLAudioElement = backgroundSong as HTMLAudioElement;

        // set audio settings
        audioTag.volume = volume;
        audioTag.loop = data.loop;

        // add delay to autoplay if delay is given
        if (delay !== 0) {
            setTimeout(() => {
                audioTag.play();
            }, delay);
        } else {
            audioTag.play();
        }
    }, [data.loop, volume, delay]);

    const stopAudio = () => {
        // get audio tag
        const backgroundSong = document.getElementById('backgroundSong');
        const audioTag: HTMLAudioElement = backgroundSong as HTMLAudioElement;

        audioTag.pause();
    };

    const acceptAudio = () => {
        setShowAudioOptions({ show: true, isAllowed: true });
        closeOverlay();
    };

    const denieAudio = () => {
        setShowAudioOptions({ show: true, isAllowed: false });
        closeOverlay();
    };

    const closeOverlay = () => {
        setExtraClasses('closing');

        setTimeout(() => {
            setShowOverlay(false);
        }, 500);
    };

    React.useEffect(() => {
        if(showAudioOptions.isAllowed) {
            playAudio();
            return;
        }

        stopAudio();
    }, [showAudioOptions, playAudio]);

    // Return fully rendered HTML
    return (
        <div className="audio">
            {
                ( showOverlay )
                    ?   <div className={`audioOverlay ${extraClasses}`}>
                            <span className="infotext">Möchtest du Musik im Hintergrund hören?</span>
                            <button className="denied" onClick={denieAudio}>Nein</button>
                            <button className="confirmed" onClick={acceptAudio}>Ja</button>
                        </div>
                    :   <></>
            }
            {
                    ( showAudioOptions.show )
                        ?   <div className="audioOptions">
                                <button className={`toggleAudio ${(showAudioOptions.isAllowed) ? 'mute' : 'unmute'}`} title="Toogle Audio" onClick={(showAudioOptions.isAllowed) ? denieAudio : acceptAudio}>TODO: Remove</button>
                            </div>
                        :   <></>
                }
            <audio
                id="backgroundSong"
                src={song}
            />
        </div>
    )
};

// Export component to be used anywhere else
export default Audio;